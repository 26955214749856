import { error } from '@/services/Log'
import { setContext, captureException } from '@sentry/react'

const promisesRetryCookieName = 'forceSingleImportAttempt'
const sleepMiliSeconds = (ms = 500) => new Promise((resolve) => setTimeout(resolve, ms))
const shouldLogError = (elapsed: number) => (functor: ((x: unknown) => unknown)): boolean => (
  elapsed > 250
  // We purposefully exclude @sentry from the check as it is a known slow import
  && !String(functor).includes('@sentry')
)
const allPromisesWithRetries = async <T extends readonly unknown[] | []>(
  promisesGenerator: () => T,
  attempts = (
    typeof document !== 'undefined' && String(document?.cookie || '').indexOf(`${promisesRetryCookieName}=true`) >= 0
      ? 1
      : 3
  ),
  currentAttempt = 1,
  startTimestamp = Date.now(),
): Promise<{ -readonly [P in keyof T]: Awaited<T[P]> }> => {
  try {
    const modules = await Promise.all(promisesGenerator())
    const elapsed = Date.now() - startTimestamp
    if (shouldLogError(elapsed)(promisesGenerator)) {
      error('allPromisesWithRetries took more than 250 miliseconds', { startTimestamp, elapsed, promisesGenerator: String(promisesGenerator) })
    }
    return modules
  } catch (promiseAllError) {
    if (currentAttempt >= attempts) {
      error('Failed to load modules error', { attempts, promiseAllError: String(promiseAllError) })
      setContext(`Failed to execute promises after ${currentAttempt} attempts`, { promisesGenerator })
      captureException(promiseAllError)
      throw promiseAllError
    }
    await sleepMiliSeconds()
    return await allPromisesWithRetries(
      promisesGenerator,
      attempts,
      currentAttempt + 1,
      startTimestamp,
    )
  }
}

export default allPromisesWithRetries
